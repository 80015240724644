'use strict'

import PouchDB from 'pouchdb';
PouchDB.plugin(require('pouchdb-find')); // npm install --save pouchdb-find

const service = process.env.VUE_APP_service_licenze || ''

const getConfigurazione = async (base, configurazioneId, subConf) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'configurazioni' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    const configurazione = await db.get(configurazioneId)

    if (subConf) {
      return configurazione[subConf] || []
    } else {
      return configurazione
    }
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

export default {
  getConfigurazione
}
