/*
  ********************************************************************************************************************
    ANALISI SU DATI PROVENIENTI DA TRASFERIMENTO SU CLOUD DI ARCHIVI FATA/MAGO ATTIVI
    IL TRASFERIMENTO E' EFFETTUATO TRAMITE MODULO "COLLECTOR" INSTALLATO SULLA MACCHINA DOVE RISIEDE IL DATABASE .MDB

    TODO: ATTENZIONE: SPOSTARE TUTTA L'ELABORAZIONE SU SERVER IN CLUOD, TOGLIERE DA CLIENT !!!!
  *********************************************************************************************************************
*/
import { _ } from 'core-js'
import collector from './collectorService'

const getMovimentiReport = async (base, apikey, filter) => {
  const startkey = creaStartKey(apikey, filter)
  const endkey = creaEndKey(apikey, filter)
  return await collector.getMovimentiReport(base, startkey, endkey)
}

const getMovimentiReportGruppi = async (base, apikey, filter, variabili, gruppi) => {
  const startkey = creaStartKey(apikey, filter)
  const endkey = creaEndKey(apikey, filter)
  const movimenti = await collector.getMovimentiReport(base, startkey, endkey)
  try {
    let dati = []
    let totali = {}
    let riepilogo = []
    for (let riga of movimenti) {
      const rigaM = {}
      for (let col of variabili) {
        // rigaM[col.descrizione] = riga[col.codice]
        rigaM[col.codice] = riga[col.codice]
        if (col.totale) {
          switch (col.totale) {
            case 'sum':
              totali[col.codice] = totali[col.codice] || 0
              totali[col.codice] += riga[col.codice]
              break;
            default:
              break;
          }
        }
      }

      // per ogni riga calcolo il valore del raggruppamento, poi reduce per lasciare solo le righe diverse
      if (gruppi && gruppi.length > 0) {
        let rg = ''
        let rgt = ''
        // let idGruppo = 0 
        // rigaM.gruppi = []
        for (let gr of gruppi) {
          if (rg) rg += '|'
          if (rgt) rgt += ' | '
          const v2 = _.get(riga, gr.codice, '---')
          const v2t = _.get(riga, gr.codice, '---')
          rg = rg + v2
          rgt = rgt + v2t
          // rigaM.gruppi.push(v2)
          // rigaM[`gruppo${idGruppo++}`] = v2
        }
        const daCancellare = _.differenceBy(variabili, gruppi, 'codice').filter(x => !x.totale).map(x => x.codice)
        for (let c of daCancellare) {
          delete rigaM[c]
        }
        rigaM.raggruppamento = rg
        rigaM.raggruppamentoTesto = rgt
      }
      dati.push(rigaM)
    }

    if (gruppi && gruppi.length > 0) {
      riepilogo = _.reduce(dati, (result, value, key) => {
        let chiave = value.raggruppamento
        let prec = result[chiave]
        if (prec) {
          for (let v of variabili.filter(x => x.totale)) {
            prec[v.codice] += value[v.codice]
          }
        } else {
          prec = value
        }
        result[chiave] = prec 
        return result
      }, {})
      riepilogo = _.sortBy(Object.values(riepilogo), 'raggruppamentoTesto')
    }
    return { dati, totali, riepilogo: riepilogo }
  } catch (error) {
    console.log('Errore getMovimentiReportGruppi', error)
    return []
  }
}

const getMovimentiMapReduceCategoria = async (base, apikey, filter, tipo) => {
  const startkey = filter.start // sistemare  creaStartKey(apikey, filter)
  const endkey = filter.end // sistemare creaEndKey(apikey, filter)
  const movimenti = await collector.getMovimentiMapReduceCategoria(base, startkey, endkey)

  const filtroTipo = movimenti.filter(x => x.key.substring(8, 11) === `_${tipo}_`)
  return filtroTipo.map(x => { return { key: x.key.slice(11), value: x.value }})
}

function creaStartKey(apikey, filter) {
  const parametri = []

  parametri.push(apikey)
  parametri.push(filter.start || '00000000')
  if (filter.puntoVendita) parametri.push(filter.puntoVendita)

  return parametri.join('|')
}

function creaEndKey(apikey, filter) {
  const parametri = []

  parametri.push(apikey)
  parametri.push(filter.end || '99999999')
  if (filter.puntoVendita) parametri.push(filter.puntoVendita)

  return parametri.join('|')
}

export default {
  getMovimentiReport,
  getMovimentiReportGruppi,
  getMovimentiMapReduceCategoria
}
